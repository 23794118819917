/* ExploreMentors.css */

.explore-mentors {
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
  }
  
  .filter-sidebar {
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .mentor-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    gap: 20px;
  }
  
  .mentor-card-wrapper {
    margin-bottom: 20px;
  }
  
  @media (max-width: 960px) {
    .mentor-grid {
      grid-template-columns: 1fr;
    }
  }
  